import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { FC, useEffect, useState } from 'react';

import { useScroll } from '../../hooks/useScroll';
import { useToggle } from '../../hooks/useToggle';
import { useLocationContext } from '../../providers/locationProvider';
import { useUserContext } from '../../providers/userProvider';
import { getLocation } from '../../services/locations';
import { Country, Module, VenueDetails } from '../../services/types';
import { SocialLinks } from '../elements/socialLinks';

import { CloseMobileNavigation } from './closeMobileNavigation';
import { LocationNavigation } from './locationNavigation';
import { Navigation } from './navigation';
import { NavigationHeader } from './navigationHeader';
import { OffsiteNavigation } from './offsiteNavigation';

type Props = {
  module?: Module;
  showLocationNav?: boolean;
};

export const Sidebar: FC<Props> = ({ module, showLocationNav = true }) => {
  const { country, location } = useLocationContext();
  const [navState, toggleNav] = useToggle(false);
  const [navScroll] = useScroll(false, 150);
  const [navLocation, setNavLocation] = useState<VenueDetails | undefined>(location);
  const [navCountry, setNavCountry] = useState<Country>(country);
  const { preferredLocation, lastSelectedLocation } = useUserContext();
  const [locationId, setLocationId] = useState<number | undefined>(location?.id);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabSubIndex, setTabSubIndex] = useState<number>(0);
  const { data, isLoading, isError } = useQuery(
    ['preferred-location'],
    () => {
      if (locationId !== undefined && !navLocation) {
        return getLocation({ id: locationId });
      } else {
        return undefined;
      }
    },
    {
      enabled: locationId !== undefined && !navLocation,
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
    },
  );
  if (data && !navLocation && !isLoading && !isError) {
    setNavLocation(data);
    setNavCountry(data.country);
  }
  if (preferredLocation && !locationId) {
    setLocationId(preferredLocation);
  } else if (lastSelectedLocation && !locationId) {
    setLocationId(lastSelectedLocation);
  }

  useEffect(() => {
    if (window && window.outerWidth < 1024) {
      if (navState) {
        setTabIndex(0);
        setTabSubIndex(0);
      } else {
        setTabIndex(-1);
        setTabSubIndex(-1);
      }
    }
  }, [navState]);

  return (
    <div
      className={classNames(
        'sidebar w-full lg:w-64 lg:overflow-hidden shrink-0 z-50 sticky lg:fixed top-0 left-0 lg:bottom-0',
        {
          'nav-open': navState,
          'nav-scrolled': navScroll,
        },
      )}>
      <header className="relative lg:bg-white lg:dark:bg-brand-navy h-full w-full lg:w-72 lg:overflow-x-hidden">
        <div className="h-full w-full lg:w-64 lg:p-4 flex flex-col">
          <NavigationHeader
            country={navCountry}
            location={navLocation}
            mobileNavOpen={navState}
            onMobileNavOpen={toggleNav}
          />
          {showLocationNav && <LocationNavigation location={navLocation} />}
          <div
            className={classNames(
              'nav-wrapper fixed bottom-0 top-0 flex flex-col justify-between flex-grow p-4 lg:p-0 bg-brand lg:bg-white lg:dark:bg-gradient-to-b from-brand-navy-light to-brand-navy transition-all duration-300',
              {
                open: navState,
              },
            )}>
            <div>
              <CloseMobileNavigation navOpen={navState} onClose={toggleNav} />
              <Navigation
                country={navCountry}
                location={navLocation}
                module={module}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
              />
            </div>
            <div>
              <OffsiteNavigation
                country={navCountry}
                location={navLocation}
                tabIndex={tabSubIndex}
              />
              <ul
                className={classNames('flex flex-wrap mx-4 lg:mb-4 lg:space-x-0 social', {
                  'justify-start gap-8 lg:justify-between lg:gap-0': navCountry == 'uk',
                  'justify-between': navCountry == 'us',
                })}>
                <SocialLinks country={navCountry} tabIndex={tabSubIndex} />
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};
