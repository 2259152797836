import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getImgProxyURL, ImgProxyOptions, ImgProxyResponse } from '../services/imgproxy';

export const useImgProxy = (
  path: string,
  options: ImgProxyOptions,
): UseQueryResult<ImgProxyResponse, unknown> => {
  return useQuery(
    ['imgproxy' + path + JSON.stringify(options)],
    () => {
      return getImgProxyURL({ path: path, options: options });
    },
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 5 * 60 * 1000,
    },
  );
};
