import classNames from 'classnames';
import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const LogoHorizontal: FC<Props> = ({ className = 'text-white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 360 60">
      <title>Topgolf Logo Horizontal</title>
      <path
        className={classNames('fill-current', className)}
        d="M253.82,325.69h0a2.68,2.68,0,1,1,5.36,0v0a2.68,2.68,0,1,1-5.36,0Zm5,0v0a2.26,2.26,0,0,0-2.28-2.29,2.29,2.29,0,0,0-2.28,2.31h0a2.28,2.28,0,1,0,4.56,0Zm-3.34-1.4h1.25c.62,0,1.08.29,1.08.89a.82.82,0,0,1-.59.83l.68,1h-.78l-.58-.84h-.41V327h-.65Zm1.21,1.31c.29,0,.46-.15.46-.37s-.18-.37-.46-.37h-.56v.74Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M243.25,280.17A85.08,85.08,0,0,0,216,284.62a85.6,85.6,0,0,0,27.25,47.2,85.6,85.6,0,0,0,27.25-47.2A85.08,85.08,0,0,0,243.25,280.17Zm15.6,27.18-.1-.09a10.81,10.81,0,0,0-.93-1,13.71,13.71,0,0,0-1.11-1l-4.21-.74c.15.18.29.35.4.51a2.89,2.89,0,0,1,.39.76,1.8,1.8,0,0,1,.11.74,1.53,1.53,0,0,1-.11.48l4.5,2.23-.54.93-4.63-2.33a3.83,3.83,0,0,1-.88.49,7,7,0,0,1-1.45.41,13.77,13.77,0,0,1-2,.2,23.62,23.62,0,0,1-2.46-.08l3,6.75a26,26,0,0,0,5.07-.31,80.73,80.73,0,0,1-10.65,12.29,81.72,81.72,0,0,1-13.93-17.32,37.92,37.92,0,0,0,4.29,2.05,46.31,46.31,0,0,0,6,1.95,47.22,47.22,0,0,0,6.34,1.15l-2.25-6.83c-.94-.16-1.88-.36-2.81-.6s-1.86-.53-2.74-.84-1.68-.65-2.43-1a20.83,20.83,0,0,1-1.9-1l-6.5,1.65-.69-1.31,5.79-1.31a7.69,7.69,0,0,1-1.12-1.06,4.24,4.24,0,0,1-.63-1,2,2,0,0,1-.18-.87,1.75,1.75,0,0,1,.12-.55h0l-6.77-1.18a80.37,80.37,0,0,1-4.14-12.72,82.6,82.6,0,0,1,47.11,0A81.36,81.36,0,0,1,258.85,307.35Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M246.36,303.42a.48.48,0,0,1-.07.23.93.93,0,0,1-.44.33,2.93,2.93,0,0,1-.84.18,6.93,6.93,0,0,1-1.2,0l1.13,2.58a13.82,13.82,0,0,0,3.25,0,4.19,4.19,0,0,0,1.87-.65,1.23,1.23,0,0,0,.57-1,1.7,1.7,0,0,0-.33-.93Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M244.52,303a.31.31,0,0,0,0-.32,1.44,1.44,0,0,0-.35-.35,3.3,3.3,0,0,0-.57-.33l-.34-.15v-.54l.27.08c.34.12.67.25,1,.39a8,8,0,0,1,.83.44,3.59,3.59,0,0,1,.47.35l3.64.65a10.35,10.35,0,0,0-1.35-.95,17.71,17.71,0,0,0-1.85-.92c-.64-.28-1.34-.53-2-.76q-.45-.15-.93-.27v-.49l1,.28c.41.13.82.26,1.21.4s.8.28,1.18.43l1.56-.35c-.55-.21-1.11-.41-1.67-.6s-1.12-.37-1.69-.54-1.06-.31-1.6-.45v-7.15c2.62-1.4,5.54.73,7.42-3.09-2.52.78-4.06-1.3-7.42-1.33h-1.56v14l-.4,0a2.47,2.47,0,0,0-.63,0,.9.9,0,0,0-.41.16.28.28,0,0,0-.11.28.67.67,0,0,0,.24.36,2.68,2.68,0,0,0,.57.38,6.17,6.17,0,0,0,.81.34,8.8,8.8,0,0,0,1,.23,4.76,4.76,0,0,0,.87.08,2.11,2.11,0,0,0,.63-.09A.53.53,0,0,0,244.52,303Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M252.37,302.42c-.54-.28-1.09-.56-1.65-.81-.36-.17-.73-.33-1.1-.48l-1.56.39.62.29c.37.18.72.37,1.06.57s.69.41,1,.62.5.36.73.54l3.93.71c-.45-.32-.92-.62-1.39-.91S252.93,302.71,252.37,302.42Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M234.77,298.82l-2.59-1.3q-.92,0-1.8.09c-.63.05-1.25.13-1.85.23a14.09,14.09,0,0,0-1.81.41,10.58,10.58,0,0,0-1.53.58l6,1.08a4,4,0,0,1,.61-.39,5.27,5.27,0,0,1,.9-.34,8.86,8.86,0,0,1,1-.23C234.06,298.89,234.41,298.85,234.77,298.82Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M237,298.78c.4,0,.81,0,1.23.08l1.26.15c.39.05.78.11,1.18.19l-.29-.9c-.58-.12-1.15-.22-1.71-.31l-1.81-.24c-.61-.07-1.22-.12-1.81-.16l-.74,0,2.47,1.22Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M236.72,299.49a7.08,7.08,0,0,0-1.69.31,2.54,2.54,0,0,0-1.05.61l4.42.79a.91.91,0,0,1,.3-.23,2.7,2.7,0,0,1,.67-.18,5.83,5.83,0,0,1,.87-.05c.28,0,.59,0,.91.07l-.35-1.08a20.76,20.76,0,0,0-2.09-.25A14.56,14.56,0,0,0,236.72,299.49Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M240.83,303.47a9,9,0,0,1-1.2-.53,5.2,5.2,0,0,1-.85-.58,3.21,3.21,0,0,1-.32-.32l-4.82-.85a2,2,0,0,0,.44,1.12,7.06,7.06,0,0,0,1.83,1.54,17.6,17.6,0,0,0,3.16,1.51,24.25,24.25,0,0,0,3.94,1.09l-.85-2.58A12.91,12.91,0,0,1,240.83,303.47Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M552.29,321.47v0a2.7,2.7,0,0,1,2.69-2.69,2.67,2.67,0,0,1,2.68,2.68h0a2.69,2.69,0,1,1-5.37,0Zm5,0h0a2.28,2.28,0,1,0-4.56,0v0a2.26,2.26,0,0,0,2.28,2.29A2.29,2.29,0,0,0,557.26,321.45Zm-3.35-1.39h1.26c.62,0,1.08.29,1.08.89a.83.83,0,0,1-.59.83l.68,1h-.78l-.58-.84h-.42v.84h-.65Zm1.22,1.31c.29,0,.46-.15.46-.37s-.18-.37-.46-.37h-.57v.74Z"
        transform="translate(-216 -280.17)"
      />
      <polygon
        className={classNames('fill-current', className)}
        points="61.57 6.65 60.74 16.11 74.68 16.11 72.24 43.98 81.7 43.98 84.14 16.11 98.07 16.11 98.9 6.65 61.57 6.65"
      />
      <path
        className={classNames('fill-current', className)}
        d="M486,286.83H467.39a12.18,12.18,0,0,0-11.88,10.92l-1.35,15.48a9.86,9.86,0,0,0,10,10.92H482.7a12.17,12.17,0,0,0,11.88-10.92l1.36-15.48A9.86,9.86,0,0,0,486,286.83Zm-.85,26.4a1.62,1.62,0,0,1-1.59,1.46H465a1.32,1.32,0,0,1-1.33-1.46L465,297.75a1.64,1.64,0,0,1,1.59-1.46h18.58a1.32,1.32,0,0,1,1.34,1.46Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M347.33,286.83H328.75a12.17,12.17,0,0,0-11.88,10.92l-1.36,15.48a9.87,9.87,0,0,0,10,10.92h18.58a12.18,12.18,0,0,0,11.88-10.92l1.35-15.48A9.85,9.85,0,0,0,347.33,286.83Zm-.85,26.4a1.63,1.63,0,0,1-1.59,1.46H326.31a1.31,1.31,0,0,1-1.33-1.46l1.35-15.48a1.62,1.62,0,0,1,1.59-1.46H346.5a1.32,1.32,0,0,1,1.33,1.46Z"
        transform="translate(-216 -280.17)"
      />
      <polygon
        className={classNames('fill-current', className)}
        points="296.52 6.65 287.06 6.65 283.8 43.98 318.03 43.98 318.86 34.52 294.09 34.52 296.52 6.65"
      />
      <path
        className={classNames('fill-current', className)}
        d="M393.77,286.83h-29.5L361,324.15h9.46l.82-9.28h20a12.18,12.18,0,0,0,11.88-10.93l.54-6.19A9.86,9.86,0,0,0,393.77,286.83Zm0,17.11a1.64,1.64,0,0,1-1.59,1.46h-20l.79-9.11h20a1.32,1.32,0,0,1,1.34,1.46Z"
        transform="translate(-216 -280.17)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M439.08,308.6l-.39,4.63a1.63,1.63,0,0,1-1.59,1.46H418.51a1.32,1.32,0,0,1-1.34-1.46l1.36-15.48a1.62,1.62,0,0,1,1.59-1.46h29.5l.83-9.46H421a12.17,12.17,0,0,0-11.88,10.92l-1.36,15.48a9.87,9.87,0,0,0,10,10.92h23.25a7,7,0,0,0,6.81-6.26l1.35-15.58h-17.5l7.49,6.29"
        transform="translate(-216 -280.17)"
      />
      <polygon
        className={classNames('fill-current', className)}
        points="359.17 16.11 360 6.65 325.77 6.65 322.5 43.98 331.96 43.98 333.05 31.6 354.72 31.6 355.55 22.14 333.88 22.14 334.4 16.11 359.17 16.11"
      />
    </svg>
  );
};
