import { axios, buildAxios } from '../utils/axios';

import {
  ListResponse,
  LocationModule,
  LocationOptions,
  LocationSelectOptions,
  LocationWithModulesOptions,
  SelectVenue,
  Venue,
  VenueDetails,
  VenueWithModules,
} from './types';

type GetLocations = (
  params?: Partial<LocationOptions>,
) => Promise<ListResponse<Venue, LocationOptions>>;

export const getLocations: GetLocations = async ({
  lookup = true,
  page = 1,
  limit = 20,
  latitude,
  longitude,
  country,
  preferred,
  open,
} = {}) => {
  const response = await axios.get<ListResponse<Venue, LocationOptions>>('locations/list', {
    params: { lookup, page, limit, latitude, longitude, country, preferred, open },
  });

  return response.data;
};

type GetLocationsSelect = (
  params?: Partial<LocationSelectOptions>,
) => Promise<ListResponse<SelectVenue, LocationSelectOptions>>;

export const getLocationsSelect: GetLocationsSelect = async ({
  lookup = true,
  latitude,
  longitude,
  country,
  module,
  preferred,
  open,
  promo,
  campaign,
} = {}) => {
  const response = await axios.get<ListResponse<SelectVenue, LocationSelectOptions>>(
    'locations/select',
    {
      params: {
        lookup,
        latitude,
        longitude,
        country,
        module,
        preferred,
        open,
        promo,
        campaign,
      },
    },
  );

  return response.data;
};

export const getLocation = async ({ id }: { id: number | string }): Promise<VenueDetails> => {
  const response = await axios.get<VenueDetails>(`locations/${id}`);
  return response.data;
};

export const buildLocations: GetLocations = async ({
  lookup = true,
  page = 1,
  limit = 20,
  open,
  country,
} = {}) => {
  if (limit > 10 || limit === 0) {
    // build response for limit 0
    const maxLimit = 10;
    const response = await buildAxios.get<ListResponse<Venue, LocationOptions>>('locations/list', {
      params: { lookup, page, maxLimit, open, country },
    });
    while (response.data.hasMore && (response.data.returned < limit || limit === 0)) {
      page++;
      const nextResponse = await buildAxios.get<ListResponse<Venue, LocationOptions>>(
        'locations/list',
        {
          params: { lookup, page, maxLimit, open, country },
        },
      );
      response.data.data = [...response.data.data, ...nextResponse.data.data];
      response.data.returned += nextResponse.data.returned;
      response.data.hasMore = nextResponse.data.hasMore;
    }
    return response.data;
  }
  const response = await buildAxios.get<ListResponse<Venue, LocationOptions>>('locations/list', {
    params: { lookup, page, limit, open, country },
  });

  return response.data;
};

export const buildLocation = async ({ id }: { id: number | string }): Promise<VenueDetails> => {
  const response = await buildAxios.get<VenueDetails>(`locations/${id}`);
  return response.data;
};

export const buildLocationsWithModules = async <T extends LocationModule>({
  page = 1,
  limit = 20,
  country,
  modules,
  requiredModules,
  open,
}: Partial<LocationWithModulesOptions> = {}): Promise<
  ListResponse<VenueWithModules<T>, LocationWithModulesOptions>
> => {
  if (limit > 10 || limit === 0) {
    // build response for limit 0
    const maxLimit = 10;
    const response = await buildAxios.get<
      ListResponse<VenueWithModules<T>, LocationWithModulesOptions>
    >(`locations/with-modules`, {
      params: {
        maxLimit,
        page,
        country,
        modules,
        requiredModules,
        open,
      },
    });
    while (response.data.hasMore && (response.data.returned < limit || limit === 0)) {
      page++;
      const nextResponse = await buildAxios.get<
        ListResponse<VenueWithModules<T>, LocationWithModulesOptions>
      >(`locations/with-modules`, {
        params: {
          maxLimit,
          page,
          country,
          modules,
          requiredModules,
          open,
        },
      });
      const data = Object.values(nextResponse.data.data);
      data.forEach((item) => {
        response.data.data[item.id] = item;
      });
      response.data.returned += nextResponse.data.returned;
      response.data.hasMore = nextResponse.data.hasMore;
    }
    return response.data;
  }
  const response = await buildAxios.get<
    ListResponse<VenueWithModules<T>, LocationWithModulesOptions>
  >(`locations/with-modules`, {
    params: {
      limit,
      page,
      country,
      modules,
      requiredModules,
      open,
    },
  });
  return response.data;
};

type BuildLocationSelectOptions = LocationSelectOptions & {
  page?: number;
  limit?: number;
};

type BuildLocationsSelect = (
  params?: Partial<BuildLocationSelectOptions>,
) => Promise<ListResponse<SelectVenue, BuildLocationSelectOptions>>;

export const buildLocationsSelect: BuildLocationsSelect = async ({
  lookup = false,
  page = 1,
  limit = 20,
  country,
  module,
  preferred,
  open,
  promo,
  campaign,
} = {}) => {
  if (limit > 10 || limit === 0) {
    // build response for limit 0
    const maxLimit = 10;
    const response = await buildAxios.get<ListResponse<SelectVenue, BuildLocationSelectOptions>>(
      'locations/select',
      {
        params: {
          lookup,
          page,
          maxLimit,
          country,
          module,
          preferred,
          open,
          promo,
          campaign,
        },
      },
    );
    while (response.data.hasMore && (response.data.returned < limit || limit === 0)) {
      page++;
      const nextResponse = await buildAxios.get<
        ListResponse<SelectVenue, BuildLocationSelectOptions>
      >('locations/select', {
        params: {
          lookup,
          page,
          maxLimit,
          country,
          module,
          preferred,
          open,
          promo,
          campaign,
        },
      });
      response.data.data = [...response.data.data, ...nextResponse.data.data];
      response.data.returned += nextResponse.data.returned;
      response.data.hasMore = nextResponse.data.hasMore;
    }
    return response.data;
  }
  const response = await buildAxios.get<ListResponse<SelectVenue, BuildLocationSelectOptions>>(
    'locations/select',
    {
      params: {
        lookup,
        page,
        limit,
        country,
        module,
        preferred,
        open,
        promo,
        campaign,
      },
    },
  );

  return response.data;
};
