import classNames from 'classnames';
import { FC } from 'react';

import { Country } from '../../services/types';

import { PreferLocation } from './preferLocation';

type Props = {
  country: Country;
  id: number;
  location: string;
  name: string;
  address: string;
  city: string;
  state: string;
  post_code: string;
  distance: number;
  active: boolean;
  onLocationClick?: () => void;
};

export const NearestLocation: FC<Props> = ({
  id,
  country,
  location,
  name,
  address,
  city,
  state,
  post_code,
  distance,
  active = false,
}) => {
  return (
    <div className="flex space-x-2" key={id}>
      <div>
        <PreferLocation location={id} />
      </div>
      <a
        className={classNames('flex-1 border-none', {
          'hover:text-brand dark:hover:text-white': active,
        })}
        href={`/${country}/${location}/`}>
        <div
          className={classNames('font-shield-black', {
            'text-white lg:text-body dark:text-white': active,
          })}>
          {name}
        </div>
        <span>
          {address} <br /> {city}, {state} {post_code} <br />
          <small>~{Math.ceil(distance / 10) * 10} miles away</small>
        </span>
        {!active && <div className={'view'}>View Hours &amp; Pricing</div>}
      </a>
    </div>
  );
};
