import { FC, useEffect, useRef } from 'react';

type Props = {
  onClose: () => void;
  navOpen: boolean;
};

export const CloseMobileNavigation: FC<Props> = ({ onClose, navOpen }) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (navOpen && buttonRef.current) {
      buttonRef.current.focus();
    }
  }, [navOpen]);
  return (
    <div className="block w-14 -mt-3 -ml-4 lg:hidden">
      <button
        type="button"
        className="flex items-center justify-center rounded-full h-6 w-6 ml-3 mt-3 mb-1"
        onClick={onClose}
        ref={buttonRef}
        aria-expanded={navOpen}
        aria-label="Close Menu">
        <span className="leading-none">
          <i className="fas fa-times text-accent-neon text-xl leading-none" />
        </span>
      </button>
    </div>
  );
};
