import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import { s3 } from '../../utils/env';

import { Img } from './img';

type Props = {
  country: string;
};

export const PromoBanner: FC<Props> = ({ country }) => {
  const [openPopover, setOpenPopover] = useState(true);
  const router = useRouter();

  useEffect(() => {
    const savedState = localStorage.getItem('_openPopover');
    if (savedState !== null) {
      setOpenPopover(JSON.parse(savedState));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('_openPopover', JSON.stringify(openPopover));
  }, [openPopover]);

  useEffect(() => {
    const handleRouteChange = (): void => {
      setOpenPopover(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return (): void => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  const togglePopover = (): void => {
    setOpenPopover(false);
  };
  if (openPopover) {
    return (
      <>
        <div
          id="sureThingPopover"
          className="hidden animate animate-fade-in lg:left-[16rem] sticky lg:absolute lg:pt-6 z-40 lg:z-50 lg:min-h-[300px] pr-12">
          <div>
            <div className="relative w-[340px] lg:w-[390px] overflow-visible">
              <button
                className="relative hidden lg:flex left-full scale-75 lg:scale-100 lg:-translate-y-1/2 top-[8px] ml-2 lg:ml-0 lg:top-0 z-50 rounded-full bg-accent-neon w-[32px] h-[32px] justify-center items-center"
                onClick={(): void => togglePopover()}>
                <FontAwesomeIcon icon={faXmark} className="w-[20px] h-[20px] text-black" />
              </button>
              <div className="-ml-[1px] lg:-ml-6 absolute top-0 -left-4 z-40 -rotate-12 lg:rotate-0">
                <Img
                  src={s3 + 'uploads/images/misc/SureThing-club-burst.png'}
                  alt="The Sure Thing Club photo"
                  className="relative max-w-[92px] lg:max-w-[180px] lg:-mt-6"
                  width={180}
                  height={129}
                  loading={'eager'}
                />
              </div>
              <div className="sm:absolute top-0 bg-brand sm:rounded-br-lg w-screen sm:w-[412px] z-30 pt-4 pb-2 lg:py-4 shadow-2xl -mb-1">
                <div className="ml-20 lg:ml-[118px] flex flex-row items-center">
                  <h2 className="text-accent-neon font-shield-light-condensed lg:text-lg italic">
                    Swing the
                    <br />
                    <span className="font-shield-black text-2xl lg:text-4xl block ml-3">
                      Sure Thing!
                    </span>
                  </h2>
                  <div className="sm:flex-grow flex justify-center">
                    <Link href={'/' + country + '/promo/the-sure-thing-golf-club'} legacyBehavior>
                      <button
                        className="btn sm outline white !text-xxs !px-3.5 !py-1.5 ml-6 lg:ml-4 whitespace-nowrap"
                        id="home-hero-btn-sure-thing"
                        aria-label="Learn more about the Sure Thing Club"
                        onClick={(): void => togglePopover()}>
                        Learn More
                      </button>
                    </Link>
                  </div>
                  <div className="flex-grow flex justify-end pr-2">
                    <button
                      className="flex lg:hidden z-50 rounded-full bg-accent-neon w-[20px] h-[20px] justify-center items-center"
                      onClick={(): void => togglePopover()}>
                      <FontAwesomeIcon icon={faXmark} className="w-[16px] h-[16px] text-black" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative px-6 z-50">
              <div className="absolute max-w-[40px] left-10 lg:left-16 lg:max-w-[52px] -top-[24px] sm:top-[22px] lg:top-[8px]">
                <Img
                  src={s3 + 'uploads/images/misc/sure-thing-sticker-withtype-r.svg'}
                  alt="Easier to Hit"
                  width={770}
                  height={283}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return null;
};
