import { useState, useEffect, useCallback } from 'react';

export const useScroll = (defaultValue = false, offset = 150): [boolean, () => void] => {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback(() => {
    setValue(window.scrollY > offset);
  }, [offset]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      toggle();
      window.addEventListener('scroll', toggle);
      return (): void => window.removeEventListener('scroll', toggle);
    }
  }, [toggle]);

  return [value, toggle];
};
