import classNames from 'classnames';
import { FC } from 'react';

import { NearestLocations } from './nearestLocations';

type Props = {
  list: boolean;
  toggleList: () => void;
};

export const ChooseLocation: FC<Props> = ({ list, toggleList }) => {
  return (
    <>
      <div className={classNames({ hidden: list })}>
        <button
          className="flex items-center lg:justify-between w-full px-3.5 lg:px-0 h-7 sm:h-8 md:h-9 lg:h-auto"
          type="button"
          onClick={toggleList}>
          <div className="flex items-center">
            <i className="fas fa-location-arrow text-accent-cyan lg:text-brand-bright lg:dark:text-accent-cyan text-xs lg:text-sm mr-2" />
            <span className="uppercase text-xxs sm:text-xs lg:text-sm font-shield-black text-white lg:text-brand lg:dark:text-white leading-none text-left">
              Choose Location
            </span>
          </div>
          <div className="ml-4 flex items-center">
            <i className="fas fa-chevron-down text-xs leading-none text-accent-neon lg:text-green-light lg:dark:text-accent-neon" />
          </div>
        </button>
      </div>
      {list && <NearestLocations onToggle={toggleList} />}
    </>
  );
};
