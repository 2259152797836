import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { Country } from '../../services/types';
import { LocationLink } from '../locationLink';

type Props = {
  country?: Country;
  iconClass?: string;
  tabIndex?: number;
};

export const SocialLinks: FC<Props> = ({ country = 'us', iconClass, tabIndex }) => {
  const countryOptions = {
    us: {
      twitter: 'topgolf',
      tiktok: '@topgolf',
      facebook: 'topgolf',
      instagram: 'topgolf',
      youtube: 'topgolf',
    },
    uk: {
      twitter: 'topgolfuk',
      facebook: 'topgolf',
      instagram: 'topgolfuk',
      youtube: 'c/TopgolfUKTV',
    },
  };

  const social = countryOptions[country];

  return (
    <>
      <li className={classNames('block', iconClass)}>
        <Link
          href={'https://www.instagram.com/' + social.instagram}
          target="_blank"
          id="sidebar-nav-insta"
          tabIndex={tabIndex}>
          <span className="sr-only">Follow Topgolf on Instagram</span>
          <i className={classNames('fab fa-instagram fa-2x', iconClass)} aria-hidden="true" />
        </Link>
      </li>
      <li className={classNames('block', iconClass)}>
        <Link
          href="https://www.tiktok.com/@topgolf"
          target="_blank"
          id="sidebar-nav-tok"
          tabIndex={tabIndex}>
          <span className="sr-only">Follow Topgolf on TikTok</span>
          <i className={classNames('fab fa-tiktok fa-2x', iconClass)} aria-hidden="true" />
        </Link>
      </li>
      <li className={classNames('block', iconClass)}>
        <Link
          href={'https://www.youtube.com/' + social.youtube}
          target="_blank"
          id="sidebar-nav-yt"
          tabIndex={tabIndex}>
          <span className="sr-only">Follow Topgolf on YouTube</span>
          <i className={classNames('fab fa-youtube fa-2x', iconClass)} aria-hidden="true" />
        </Link>
      </li>
      <li className={classNames('block', iconClass)}>
        <Link
          href={'https://facebook.com/' + social.facebook}
          target="_blank"
          id="sidebar-nav-fb"
          tabIndex={tabIndex}>
          <span className="sr-only">Follow Topgolf on Facebook</span>
          <i className={classNames('fab fa-facebook fa-2x', iconClass)} aria-hidden="true" />
        </Link>
      </li>
      {country == 'us' && (
        <>
          <li className="block text-center md:hidden">
            <LocationLink slug="company/app" country={country}>
              <a id="sidebar-nav-app" tabIndex={tabIndex}>
                <span className="sr-only">Download the Topgolf App</span>
                <i
                  className={classNames('fas fa-mobile-alt fa-2x', iconClass)}
                  aria-hidden="true"
                />
                <span
                  className={classNames(
                    'block text-xs font-shield-semibold-condensed -mt-1',
                    iconClass,
                  )}
                  aria-hidden="true">
                  APP
                </span>
              </a>
            </LocationLink>
          </li>
        </>
      )}
    </>
  );
};
