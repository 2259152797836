import { trim } from './string';

export const getLink = (
  slug: string,
  country: string,
  location?: string,
  hash?: string,
): string => {
  return `/${country}/${location ? location + '/' : ''}${slug ? trim(slug, '/') + '/' : ''}${
    hash ? hash : ''
  }`;
};
