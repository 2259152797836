import { FC, useRef } from 'react';

import { Module, Country, VenueDetails } from '../../services/types';
import { LogoHorizontal } from '../images/logoHorizontal';
import { LogoShield } from '../images/logoShield';
import { LocationLink } from '../locationLink';

type Props = {
  mobileNavOpen: boolean;
  onMobileNavOpen: () => void;
  country: Country;
  location?: VenueDetails;
  module?: Module;
};

export const NavigationHeader: FC<Props> = ({
  country,
  location,
  mobileNavOpen,
  onMobileNavOpen,
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <div className="nav-top bg-brand lg:bg-white lg:dark:bg-brand-navy sticky lg:relative flex items-center px-4 py-3 lg:p-0">
      <div className="absolute left-4 top-1/2 transform -translate-y-1/2 lg:hidden">
        <button
          className="flex items-center"
          type="button"
          onClick={onMobileNavOpen}
          aria-expanded={mobileNavOpen}
          ref={buttonRef}>
          <span className="icon icon--bars text-accent-neon" />
          <span className="sr-only">Menu</span>
        </button>
      </div>
      <div className="logo-holder ml-8 lg:mx-4 lg:mt-4 lg:mb-2">
        <LocationLink slug={''} country={country}>
          <a className="flex items-center border-none">
            <span className="hidden lg:block">
              <LogoShield />
            </span>
            <span className="inline-block lg:hidden mt-0.5">
              <LogoHorizontal />
            </span>
          </a>
        </LocationLink>
      </div>
      <div className="absolute right-4 top-0 h-full flex items-center gap-3 lg:hidden">
        {location && location?.modules?.planvisit && (
          <>
            <LocationLink slug="plan-a-visit" country={country} location={location?.alias}>
              <a
                id="nav-btn-pav-loc"
                className="px-4 py-1 text-xxs uppercase font-shield-bold leading-none tracking-wide rounded-full bg-white !text-brand hover:bg-brand-bright hover:!text-white border-none">
                Plan a Visit
              </a>
            </LocationLink>
          </>
        )}
        {(!location || !location?.modules?.planvisit) && (
          <LocationLink slug="plan-a-visit" country={country}>
            <a
              id="nav-btn-pav-global"
              className="px-3 py-1 text-xxs uppercase font-shield-bold leading-none tracking-wide rounded-full bg-white !text-brand hover:bg-brand-bright hover:!text-white border-none">
              Plan a Visit
            </a>
          </LocationLink>
        )}
      </div>
    </div>
  );
};
