import { FC } from 'react';

import { HourDetails, Hours, SpecialHours } from '../services/types';

export const convert24to12 = (hours: number, minutes: string): string => {
  if (hours > 24) {
    return convert24to12(hours - 24, minutes);
  }

  const suffix = hours >= 12 ? 'PM' : 'AM';
  const min = parseInt(minutes) ? `:${minutes}` : '';

  return ((hours + 11) % 12) + 1 + min + suffix;
};

const dt = new Date();
dt.setHours(dt.getHours() - 3);
const day = dt.getDay();
const hour = dt.getHours();
const closedText = 'CLOSED';

const formatHours = (hours?: HourDetails | SpecialHours): string => {
  if (!hours) return closedText;

  const timeStart = hours.time_start.split(':');
  const timeEnd = hours.time_end.split(':');
  const start = convert24to12(parseInt(timeStart[0]), timeStart[1]);
  const end = convert24to12(parseInt(timeEnd[0]), timeEnd[1]);

  return `${start} - ${end}`;
};

const daysBetween = (initial: number, compare: number): number => {
  return Math.round((compare - initial) / (1000 * 60 * 60 * 24));
};

const formatDate = (day: Date): string => {
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return `${monthNames[day.getMonth()]} ${day.getUTCDate()}`;
};

export const TodayHours: FC<{ hours: Hours; specialhours: SpecialHours[] | null }> = ({
  hours,
  specialhours,
}) => {
  const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  let dayName = dayNames[day];
  if (hour < 3) {
    dayName = dayNames[day === 0 ? 6 : day - 1];
  }
  if (specialhours !== null && specialhours?.length > 0) {
    const specialHoursFormat: string[] = [];
    specialhours.filter((hours) => {
      const specialhour = new Date(hours.date + 'T11:59:00Z');
      if (specialhour.getDate() === dt.getDate() && specialhour.getMonth() === dt.getMonth()) {
        specialHoursFormat.push(hours.closed ? closedText : formatHours(hours));
      }
    });
    if (specialHoursFormat.length) {
      return (
        <>
          {dayName}:{' '}
          <span className="font-shield-bold inline-block">{specialHoursFormat.join(', ')}</span>
        </>
      );
    }
  }
  if (!hours.hours) {
    return (
      <>
        {dayName}: <span className="font-shield-bold inline-block">{closedText}</span>
      </>
    );
  }

  const days = hours.hours;
  let today = days[day];
  if (hour < 3) {
    today = days[day === 0 ? 6 : day - 1];
  }
  return (
    <>
      {dayName}: <span className="font-shield-bold inline-block">{formatHours(today)}</span>
    </>
  );
};

export const parseDays: ({
  hours,
  start,
  group,
}: {
  hours: Hours;
  start?: 'sun' | 'mon';
  group?: boolean;
}) => string[] = ({ hours, start = 'mon', group = true }): string[] => {
  if (!hours.hours) return [];
  const dayNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const localHours = [...hours.hours];
  const parsed = [];
  let i = 0;

  if (start === 'mon') {
    dayNames.push('Sun');
    dayNames.shift();
    localHours.push(localHours[0]);
    localHours.shift();
  }

  if (!group) {
    for (const day of dayNames) {
      parsed.push(`${day}: ${formatHours(localHours[i])}`);
    }

    return parsed;
  }

  let parseIndex = 0;
  let dayIndex = 0;
  let key = formatHours(localHours[dayIndex]);

  parsed[dayIndex] = `${dayNames[dayIndex]}: ${key}`;
  for (const day of dayNames) {
    const parse = formatHours(localHours[i]);
    if (key !== parse) {
      dayIndex = i;
      parseIndex++;
      key = parse;
      parsed.push(`${day}: ${parse}`);
    } else if (i !== dayIndex) {
      parsed[parseIndex] = `${dayNames[dayIndex]}-${day}: ${parse}`;
    }

    i++;
  }

  return parsed;
};

export const parseDates: ({
  dates,
  group,
}: {
  dates: SpecialHours[];
  group?: boolean;
}) => string[] = ({ dates, group = true }): string[] => {
  if (!dates) return [];
  const parsed = [];

  if (!group) {
    for (const day of dates) {
      const notes = day.notes ?? '';
      parsed.push(
        `${formatDate(new Date(day.date + 'T11:59:00Z'))}: ${
          day.closed ? closedText : formatHours(day)
        } ${notes}`,
      );
    }

    return parsed;
  }

  let initDate = new Date(0);
  let lastDate = new Date(0);
  let lastHours = '';
  let parseIndex = 0;

  for (const day of dates) {
    const notes = day.notes ?? '';
    const thisDate = new Date(day.date + 'T11:59:00Z');
    const thisHours = day.closed ? closedText : formatHours(day);
    const keySince = daysBetween(lastDate.getTime(), thisDate.getTime());

    if (keySince == 0) {
      // If Same Day
      parsed[parseIndex - 1] = `${formatDate(thisDate)}: ${lastHours}, ${thisHours} ${notes}`;
      lastHours = thisHours;
    } else if (keySince == 1 && lastHours === thisHours) {
      // If Consecutive Day with Same Hours
      lastDate = thisDate;
      parsed[parseIndex - 1] = `${formatDate(initDate)} - ${formatDate(
        thisDate,
      )}: ${thisHours} ${notes}`;
      lastHours = thisHours;
    } else {
      // Catch the Rest
      initDate = thisDate;
      lastDate = thisDate;
      lastHours = thisHours;
      parsed.push(`${formatDate(thisDate)}: ${thisHours} ${notes}`);
      parseIndex++;
    }
  }

  return parsed;
};

export const convertToMilitaryTime = (timeStr: string): string => {
  const [time, modifier] = timeStr.split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  // Convert hours to 24-hour format
  if (modifier === 'PM' && hours !== '12') {
    hours = String(parseInt(hours, 10) + 12);
  }
  if (modifier === 'AM' && hours === '12') {
    hours = '00';
  }

  const militaryTime = `${hours}${minutes}`;

  return militaryTime;
};
