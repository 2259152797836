import { useEffect, useState } from 'react';

import { useUserContext } from '../providers/userProvider';

export const useGeolocation = (): Pick<GeolocationCoordinates, 'latitude' | 'longitude'> & {
  error: null | string;
  loaded: boolean;
} & Pick<ReturnType<typeof useUserContext>, 'setLatLng'> => {
  const {
    loaded: userContextLoaded,
    latitude: localLatitude,
    longitude: localLongitude,
    setLatLng: localSetLatLng,
  } = useUserContext();

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [fromLocal, setFromLocal] = useState(false);

  const loadGeo = (): void => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (s) => {
          setError(null);
          setLatitude(s.coords.latitude);
          setLongitude(s.coords.longitude);
          setLoaded(true);
          setFromLocal(false);
        },
        (e) => {
          setError(e.message);
          setLoaded(true);
          setFromLocal(false);
        },
      );
    } else {
      setError('Geolocation is not available');
      setLoaded(true);
      setFromLocal(false);
    }
  };

  useEffect(() => {
    if (!userContextLoaded) return;

    if (!localLatitude || !localLongitude) {
      loadGeo();
      return;
    }

    setLatitude(localLatitude);
    setLongitude(localLongitude);
    setFromLocal(true);
    setError(null);
    setLoaded(true);
  }, [userContextLoaded, localLatitude, localLongitude]);

  const setLatLng: typeof localSetLatLng = (latitude, longitude) => {
    if (fromLocal) return;

    localSetLatLng(latitude, longitude);
  };

  return {
    loaded,
    error,
    latitude,
    longitude,
    setLatLng,
  };
};
