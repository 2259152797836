import { Agent } from 'http';
import { Agent as AgentHTTPS } from 'https';

import ax from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import axiosRetry, { isRetryableError } from 'axios-retry';

const cacheOptions = {
  ttl: 15 * 60 * 1000,
  interpretHeader: true,
};

const axPublic = ax.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || '',
});

axiosRetry(axPublic, {
  retries: 2,
  retryCondition: isRetryableError,
});

export const axios = setupCache(axPublic, cacheOptions);

const httpAgent = new Agent({ keepAlive: true });
const httpsAgent = new AgentHTTPS({ keepAlive: true });

const axBuild = ax.create({
  httpAgent,
  httpsAgent,
  baseURL: (process.env.BUILD_URL || process.env.NEXT_PUBLIC_API_URL || '') + 'build/',
  auth: {
    username: process.env.BUILD_USERNAME || '',
    password: process.env.BUILD_PASSWORD || '',
  },
});

axiosRetry(axBuild, {
  retries: 3,
  retryCondition: isRetryableError,
});

export const buildAxios = setupCache(axBuild, cacheOptions);
