import classNames from 'classnames';
import Link from 'next/link';
import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';

import { usePathActive } from '../../hooks/usePathActive';
import { Module, Country, VenueDetails } from '../../services/types';
import { LocationLink } from '../locationLink';

import { SubMenu } from './subMenu';

type Params = {
  className?: string;
  country: Country;
  location?: VenueDetails;
  module?: Module;
  tabIndex: number;
  setTabIndex: Dispatch<SetStateAction<number>>;
};

type SubMenuType = '' | 'howItWorks' | 'playMore';

export const Navigation: FC<Params> = ({ className, country, location, tabIndex, setTabIndex }) => {
  const loaded = useRef(false);
  const [subMenu, setSubMenu] = useState<SubMenuType>('');

  const { isPathActive } = usePathActive();

  useEffect(() => {
    if (!loaded.current) {
      setTimeout(() => {
        loaded.current = true;
      }, 400);
    }
  }, []);
  const handleOpenSubMenu = (open: SubMenuType) => (): void => {
    setSubMenu(open);
    setTabIndex(open === '' ? 0 : -1);
  };

  return (
    <nav className={classNames('mt-6 mb-4 lg:mt-0', className, { animate: loaded.current })}>
      <button
        type="button"
        className={classNames(
          'nav-back px-4 py-2 font-shield text-xs uppercase font-normal text-accent-neon lg:text-brand-bright lg:dark:text-accent-neon',
          {
            hidden: !subMenu,
          },
        )}
        onClick={handleOpenSubMenu('')}>
        Back
      </button>
      <ul className={classNames('nav relative', { opened: subMenu })}>
        <li>
          {(!location || location?.modules?.planvisit) && (
            <LocationLink slug="plan-a-visit" country={country} location={location?.alias}>
              <a
                id="nav-menu-planavisit"
                className={classNames({
                  active: isPathActive('plan-a-visit', country, location?.alias),
                })}
                tabIndex={tabIndex}>
                Plan A Visit
              </a>
            </LocationLink>
          )}
          {location && !location?.modules?.planvisit && (
            <LocationLink slug="plan-a-visit" country={country}>
              <a
                id="nav-menu-planavisit"
                className={classNames({
                  active: isPathActive('plan-a-visit', country, undefined),
                })}
                tabIndex={tabIndex}>
                Plan A Visit
              </a>
            </LocationLink>
          )}
        </li>
        {(!location || location?.modules?.games) && (
          <li className="flex items-center gap-2 relative z-10">
            <LocationLink slug="play/games" country={country} location={location?.alias}>
              <a
                className={classNames({
                  active: isPathActive('play/games', country, location?.alias),
                })}>
                Our Games
              </a>
            </LocationLink>
            {(location?.alias == 'glasgow' || country == 'us') && (
              <span
                id="flag-games"
                className="hidden typeinbox cyan text-brand text-xs uppercase font-shield-bold leading-none">
                New!
              </span>
            )}
          </li>
        )}
        {(!location || location?.modules?.menu) && (
          <li>
            <LocationLink slug="menu" country={country} location={location?.alias}>
              <a
                className={classNames({ active: isPathActive('menu', country, location?.alias) })}
                tabIndex={tabIndex}>
                Food &amp; Drink
              </a>
            </LocationLink>
          </li>
        )}
        {(!location || location?.modules?.booking) && (
          <li className="flex items-center gap-2 relative z-10">
            <LocationLink slug="plan-an-event" country={country} location={location?.alias}>
              <a
                id="nav-menu-parties"
                className={classNames('leading-none', {
                  active: isPathActive('plan-an-event', country, location?.alias),
                })}
                tabIndex={tabIndex}>
                Parties &amp; Events
              </a>
            </LocationLink>
            <span
              id="flag-events"
              className="hidden typeinbox cyan text-brand text-xs uppercase font-shield-bold leading-none">
              Promo
            </span>
          </li>
        )}
        <SubMenu
          title="Play More"
          isOpen={subMenu === 'playMore'}
          onOpen={handleOpenSubMenu('playMore')}
          tabIndex={tabIndex}>
          <ul>
            <li>
              <LocationLink slug="experience" country={country} location={location?.alias}>
                <a
                  className={classNames({
                    active: isPathActive('experience', country, location?.alias),
                  })}>
                  How to Play
                </a>
              </LocationLink>
            </li>
            {country == 'us' && (!location || location?.modules?.memberships) && (
              <li>
                <LocationLink
                  slug="pricing/memberships"
                  country={country}
                  location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('pricing/memberships', country, location?.alias),
                    })}>
                    {location?.alias == 'las-vegas' || location?.alias == 'el-segundo'
                      ? ''
                      : 'Platinum'}{' '}
                    Memberships
                  </a>
                </LocationLink>
              </li>
            )}
            {location?.alias === 'glasgow' && (
              <li>
                <LocationLink slug="pricing" country={country} location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('pricing', country, location?.alias),
                    })}>
                    Platinum Memberships
                  </a>
                </LocationLink>
              </li>
            )}
            {(!location || location?.modules?.lessons) && (
              <li>
                <LocationLink slug="play/golf-lessons" country={country} location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('play/golf-lessons', country, location?.alias),
                    })}>
                    Golf Lessons
                  </a>
                </LocationLink>
              </li>
            )}
            {country == 'us' && location?.alias !== 'las-vegas' && (
              <li>
                <Link
                  href="https://shop.topgolf.com/?utm_source=tg_site&utm_medium=tg_site_links&utm_campaign=none&utm_ad group=none&utm_keyword=none&utm_tactic=sitelink&utm_metric=none&utm_placement=main-nav-play-more&utm_category=clubs&utm_landing=tg_shop_home&utm_content=sitelink&utm_promotion=none&utm_audience=none&utm_cdp=none&utm_team=cgi"
                  target="_blank"
                  className="">
                  Shop Clubs &amp; Gear
                </Link>
              </li>
            )}
            {location?.modules?.minigolf && (
              <li>
                {country == 'uk' ? (
                  <LocationLink
                    slug="play/adventure-golf"
                    country={'uk'}
                    location={location?.alias}>
                    <a
                      className={classNames('leading-tight', {
                        active: isPathActive('play/adventure-golf', country, location?.alias),
                      })}>
                      Adventure Golf
                    </a>
                  </LocationLink>
                ) : (
                  <LocationLink slug="play/mini-golf" country={'us'} location={location?.alias}>
                    <a
                      className={classNames('leading-tight', {
                        active: isPathActive('play/mini-golf', country, location?.alias),
                      })}>
                      Mini Golf
                    </a>
                  </LocationLink>
                )}
              </li>
            )}
            {location?.alias == 'surrey' && (
              <li>
                <LocationLink slug="play/foot-golf" country={'uk'} location={'surrey'}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('play/foot-golf', country, location?.alias),
                    })}>
                    Footgolf
                  </a>
                </LocationLink>
              </li>
            )}
            {location?.alias == 'watford' && (
              <li>
                <LocationLink slug="promo/arcade" country={'uk'} location={'watford'}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('promo/arcade', country, location?.alias),
                    })}>
                    Arcade
                  </a>
                </LocationLink>
              </li>
            )}
            {location?.modules?.callaway && (
              <li>
                <LocationLink
                  slug="play/golf-lessons/club-fitting"
                  country={country}
                  location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive(
                        'play/golf-lessons/club-fitting',
                        country,
                        location?.alias,
                      ),
                    })}>
                    {location?.alias == 'las-vegas' || location?.alias == 'renton'
                      ? 'Fitting Studio'
                      : 'Club Fitting'}
                  </a>
                </LocationLink>
              </li>
            )}
            {location?.alias === 'las-vegas' && (
              <>
                <li>
                  <LocationLink slug="play/pool" country={country} location={location?.alias}>
                    <a
                      className={classNames('leading-tight', {
                        active: isPathActive('play/topgolf-gear', country, location?.alias),
                      })}>
                      Hideaway Pool
                    </a>
                  </LocationLink>
                </li>
                <li>
                  <LocationLink
                    slug="play/topgolf-gear"
                    country={country}
                    location={location?.alias}>
                    <a
                      className={classNames('leading-tight', {
                        active: isPathActive('play/topgolf-gear', country, location?.alias),
                      })}>
                      The Shop
                    </a>
                  </LocationLink>
                </li>
              </>
            )}
            {country == 'us' && (!location || location?.modules?.kidzone) && (
              <li>
                <LocationLink slug="play/families" country={country} location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('play/families', country, location?.alias),
                    })}>
                    Kids &amp; Families
                  </a>
                </LocationLink>
              </li>
            )}
            {country == 'uk' && (!location || location?.modules?.kidzone) && (
              <li>
                <LocationLink slug="junior-zone" country={country} location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('junior-zone', country, location?.alias),
                    })}>
                    Junior Zone
                  </a>
                </LocationLink>
              </li>
            )}
            {(!location || location?.modules?.leagues) && (
              <li>
                <LocationLink slug="play/leagues" country={country} location={location?.alias}>
                  <a
                    className={classNames('leading-tight', {
                      active: isPathActive('play/leagues', country, location?.alias),
                    })}>
                    Seasonal Leagues
                  </a>
                </LocationLink>
              </li>
            )}
            {country == 'uk' && (
              <li>
                <LocationLink
                  slug="email-and-text-alerts"
                  country={country}
                  location={location?.alias}>
                  <a
                    className={classNames({
                      active: isPathActive('email-and-text-alerts', country, location?.alias),
                    })}>
                    Offers &amp; Updates
                  </a>
                </LocationLink>
              </li>
            )}
            {country == 'us' && (
              <li>
                <LocationLink
                  slug="email-and-text-alerts"
                  country={country}
                  location={location?.alias}>
                  <a
                    className={classNames({
                      active: isPathActive('email-and-text-alerts', country, location?.alias),
                    })}>
                    Offers &amp; Updates
                  </a>
                </LocationLink>
              </li>
            )}
          </ul>
        </SubMenu>
        {(!location || location?.modules?.promos) && (
          <li>
            <LocationLink slug="promo" country={country} location={location?.alias}>
              <a
                id="nav-menu-promos"
                className={classNames({ active: isPathActive('promo', country, location?.alias) })}
                tabIndex={tabIndex}>
                Promotions
              </a>
            </LocationLink>
          </li>
        )}
        <li className="flex items-center gap-2 relative z-10">
          {country == 'uk' ? (
            <LocationLink slug="gift-cards" country={country} location={location?.alias}>
              <a
                id="nav-menu-giftcards"
                className={classNames({
                  active: isPathActive('gift-cards', country, location?.alias),
                })}
                tabIndex={tabIndex}>
                Gift Cards
              </a>
            </LocationLink>
          ) : (
            <>
              <LocationLink slug="gift-cards" country={country}>
                <a
                  id="nav-menu-giftcards"
                  className={classNames({
                    active: isPathActive('gift-cards', country, undefined),
                  })}
                  tabIndex={tabIndex}>
                  Gift Cards
                </a>
              </LocationLink>
              <span
                id="flag-gc"
                className="hidden typeinbox cyan text-brand text-xs uppercase font-shield-bold leading-none">
                Offer!
              </span>
            </>
          )}
        </li>
      </ul>
    </nav>
  );
};
