import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { useToggle } from '../../hooks/useToggle';

type ContentBlockProps = {
  wrapperID?: string;
  wrapperClass?: string;
  bgColor?: string;
  bgHeight?: string;
  containerClass?: string;
  partialBg?: boolean;
  hideoverflow?: boolean;
  padtop?: boolean;
  padbottom?: boolean;
  padright?: boolean;
  padleft?: boolean;
  bleedleft?: boolean;
  bleedright?: boolean;
  hasDots?: boolean;
  hasDotsRight?: boolean;
  hasDotsTop?: boolean;
  hasDotsTopLg?: boolean;
  hasDotsFull?: boolean;
  children?: React.ReactNode;
};

export const ContentBlock: FC<ContentBlockProps> = ({
  wrapperID = '',
  wrapperClass = '',
  bgColor = 'bg-white-blue dark:bg-gray',
  bgHeight = 'h-1/2',
  containerClass = 'bg-transparent',
  partialBg = false,
  hideoverflow = true,
  padtop = true,
  padbottom = true,
  padright = true,
  padleft = true,
  bleedleft = false,
  bleedright = false,
  hasDots = false,
  hasDotsRight = false,
  hasDotsTop = false,
  hasDotsTopLg = false,
  hasDotsFull = false,
  children,
}) => {
  return (
    <section className={classNames('content relative', wrapperClass)} id={wrapperID}>
      {partialBg && <div className={classNames('absolute inset-0', bgHeight, bgColor)} />}
      <div
        className={classNames('container mx-auto', containerClass, {
          'overflow-hidden': hideoverflow,
          'pt-8 lg:pt-16': padtop,
          'pb-8 lg:pb-16': padbottom,
          'pr-4 md:pr-8 lg:pr-16': padright,
          'pl-4 md:pl-8 lg:pl-16': padleft,
          'container--bleed-left': bleedleft,
          'container--bleed-right': bleedright,
          'relative has-dots z-10': hasDots,
          right: hasDotsRight,
          top: hasDotsTop,
          'top-lg': hasDotsTopLg,
          'full-dots': hasDotsFull,
        })}>
        {children}
      </div>
    </section>
  );
};

type AlertBoxProps = {
  wrapperClass?: string;
  accent?: string;
  title?: string;
  children?: React.ReactNode;
};

export const AlertBox: FC<AlertBoxProps> = ({
  wrapperClass = 'bg-white-blue dark:bg-brand-navy my-6',
  accent = 'accent-red',
  title,
  children,
}) => {
  return (
    <div
      className={classNames(
        'p-4 border-l-4 lg:border-l-8 no-m-last leading-snug shadow-lg',
        'border-' + accent,
        wrapperClass,
      )}>
      {title && <span className="block mb-4 h5 shieldbold text-uppercase">{title}</span>}
      {children}
    </div>
  );
};

type ExpanderProps = {
  title: string | ReactNode;
  wrapperClass?: string;
  titleClass?: string;
  children?: React.ReactNode;
};

export const Expander: FC<ExpanderProps> = ({
  wrapperClass = 'bg-white dark:bg-brand-navy',
  titleClass = 'bg-white-blue dark:bg-brand-navy font-shield-bold',
  title,
  children,
}) => {
  const [expanded, setExpanded] = useToggle(false);
  return (
    <div className="expander">
      <div
        className={classNames('border border-accent-lilac dark:border-gray rounded', wrapperClass)}>
        <label className={classNames('py-4 px-6 flex label cursor-pointer rounded', titleClass)}>
          <div className="my-auto mr-auto leading-tight">{title}</div>
          <button
            aria-label={expanded ? 'Collapse button' : 'Expand Button'}
            aria-expanded={expanded}
            onClick={setExpanded}
            className={classNames('fas my-auto ml-6 text-brand-bright dark:text-accent-neon', {
              'fa-plus-square': !expanded,
              'fa-minus-square': expanded,
            })}
          />
        </label>
        <div
          className={classNames('py-3 px-6 copy animate animate-fade-in', { hidden: !expanded })}>
          {children}
        </div>
      </div>
    </div>
  );
};

type ExternalLinkProps = {
  iconColor?: string;
};

export const ExternalLink: FC<ExternalLinkProps> = ({
  iconColor = 'text-white dark:text-accent-cyan',
}) => {
  return (
    <>
      <div className="inline-block leading-none">
        <i
          className={classNames(
            'fas fa-external-link-alt text-[9px] h-3 opacity-75 self-center ml-[7px] leading-none',
            iconColor,
          )}
        />
        <span className="sr-only">
          {' '}
          Opens an external site in a new window that may not meet accessibility guidelines.
        </span>
      </div>
    </>
  );
};
