import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { useUserContext } from '../providers/userProvider';
import { getLocations } from '../services/locations';
import { Await, Country } from '../services/types';

import { useGeolocation } from './useGeolocation';

type UseNearestLocations = (params?: {
  limit?: number;
  country?: Country;
  lat?: number;
  long?: number;
}) => {
  data?: Await<ReturnType<typeof getLocations>>[];
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<InfiniteQueryObserverResult<Await<ReturnType<typeof getLocations>>, unknown>>;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
};

export const useNearestLocations: UseNearestLocations = ({
  limit = 20,
  country,
  lat = 0,
  long = 0,
} = {}) => {
  const { error: geoError, longitude, latitude, loaded, setLatLng } = useGeolocation();
  const params: Parameters<typeof getLocations>[0] = { limit, country };
  const { preferredLocation } = useUserContext();

  if (preferredLocation) {
    params.preferred = preferredLocation;
  }

  if (!geoError && loaded) {
    params.lookup = false;
    params.latitude = latitude;
    params.longitude = longitude;
  }
  if (lat && long) {
    params.lookup = false;
    params.latitude = lat;
    params.longitude = long;
  }

  const { data, isLoading, isError, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      ['nearest-locations', limit, country, lat, long],
      (context) => {
        return getLocations({ ...params, page: context.pageParam });
      },
      {
        enabled: loaded,
        cacheTime: 5 * 60 * 1000,
        staleTime: 5 * 60 * 1000,
        getNextPageParam: (lastPage) => (lastPage.hasMore ? lastPage.params.page + 1 : undefined),
        onSuccess: (data) => {
          if (data.pages[0] && data.pages[0].params.latitude && data.pages[0].params.longitude) {
            setLatLng(data.pages[0].params.latitude, data.pages[0].params.longitude);
          }
        },
      },
    );

  return {
    data: data?.pages,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
