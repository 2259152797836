import Head from 'next/head';
import Script, { ScriptProps } from 'next/script';
import { FC, useEffect } from 'react';

import { Country } from '../../services/types';
import { axios } from '../../utils/axios';
import { assetPrefix, isDev, isProd } from '../../utils/env';

type Props = {
  cookieBanner?: boolean;
  country: Country;
  strategy?: ScriptProps['strategy'];
};

const dynamicYieldID = isProd ? '8778785' : '8778784';
// live : '35ea9b46-5ed9-405a-ae05-4c34789d5c01';
// dev  : '5f5a150c-b8cc-4950-adf3-bb9ce9c7c1e3';

type DYcookieUpdate = {
  message: string;
};

const updateDYCookie = async (dyid: string): Promise<DYcookieUpdate> => {
  const response = await axios.get(`/dy/cookie`, {
    params: {
      dyid: dyid,
    },
    withCredentials: true,
  });

  return response.data;
};

export const Tracking: FC<Props> = ({
  cookieBanner = true,
  strategy = 'beforeInteractive',
  country,
}) => {
  const scriptProps: ScriptProps = {
    strategy: strategy,
  };
  const optanonID = isProd
    ? '0b298f86-e685-42e9-b9d6-d0c8d518f0b7'
    : '0b298f86-e685-42e9-b9d6-d0c8d518f0b7-test';
  useEffect(() => {
    if (isDev) {
      return;
    }
    const checkCookie = (cookieName: string): boolean => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${cookieName}=`)) {
          return true;
        }
      }
      return false;
    };
    const getCookieValue = (cookieName: string): string | null => {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(`${cookieName}=`)) {
          return cookie.substring(cookieName.length + 1);
        }
      }
      return null;
    };
    if (document) {
      if (checkCookie('_dyid') && !checkCookie('_dyid_server')) {
        const dyidValue = getCookieValue('_dyid');
        if (dyidValue !== null) {
          (async (): Promise<void> => {
            try {
              const data = await updateDYCookie(dyidValue);
              if (data) {
                return;
              }
            } catch (err) {
              console.error(err);
            }
          })();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
  return (
    <>
      <Head>
        <link rel="preconnect" href="//cdn.cookielaw.org" />
        <link rel="preconnect" href="//cdn.dynamicyield.com" />
        <link rel="preconnect" href="//st.dynamicyield.com" />
        <link rel="preconnect" href="//rcom.dynamicyield.com" />
        <link rel="dns-prefetch" href="//cdn.dynamicyield.com" />
        <link rel="dns-prefetch" href="//st.dynamicyield.com" />
        <link rel="dns-prefetch" href="//rcom.dynamicyield.com" />
      </Head>
      {/* <Script
        id="dy-ctx"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.DY = window.DY || {};
          DY.recommendationContext = { type: "${
            loc.asPath === '/us/' ? 'HOMEPAGE' : 'OTHER'
          }", data: "${loc.asPath}"};`,
        }}
        {...scriptProps}
      /> */}
      {!isDev && (
        <>
          <Script id="datalayer" src={assetPrefix + 'js/datalayer.js'} {...scriptProps} />
          <Script
            id="dy-dynamic"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldID}/api_dynamic.js`}
            {...scriptProps}
          />
          <Script
            id="dy-static"
            src={`//cdn.dynamicyield.com/api/${dynamicYieldID}/api_static.js`}
            {...scriptProps}
          />
        </>
      )}
      {cookieBanner && (
        <>
          <Script
            id="onetrust"
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            data-domain-script={optanonID}
            {...scriptProps}
          />
          {!isDev && (
            <>
              {country == 'us' ? (
                <Script
                  id="dy-consent2023"
                  src={`${assetPrefix}js/dy-consent2023.js`}
                  {...scriptProps}
                />
              ) : (
                <Script
                  id="dy-consent2023"
                  src={`${assetPrefix}js/dy-consent2023.uk.js`}
                  {...scriptProps}
                />
              )}
            </>
          )}
          <Script id="onetrust2023" src={`${assetPrefix}js/optanon2023.js`} {...scriptProps} />
        </>
      )}
      <noscript>
        {isProd ? (
          <iframe
            src="//www.googletagmanager.com/ns.html?id=GTM-T95L9TX"
            height={0}
            width={0}
            title="GoogleAnalytics"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        ) : (
          <iframe
            src="//www.googletagmanager.com/ns.html?id=GTM-MHV4QK"
            height={0}
            width={0}
            title="GoogleAnalytics"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        )}
      </noscript>
    </>
  );
};
