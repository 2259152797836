import { axios } from '../utils/axios';
import { getExtension } from '../utils/img';

export type ImgProxyOptions = {
  fit?: string;
  width?: number;
  height?: number;
  gravity?: string;
  crop?: string;
  format?: string;
  quality?: number;
};

type ImgProxyRequest = {
  path: string;
  options?: ImgProxyOptions;
};

export type ImgProxyResponse = {
  url: string;
  path: string;
};

export const getImgProxyURL = async ({
  path,
  options,
}: ImgProxyRequest): Promise<ImgProxyResponse> => {
  // check if string is pointing to s3.topgolf.com
  // if so, use the imgproxy service
  if (path.includes('s3.topgolf.com') && !['gif', 'svg'].includes(getExtension(path))) {
    const response = await axios.get<ImgProxyResponse>('urls/image', {
      params: {
        path: path,
        fit: options?.fit,
        width: options?.width,
        height: options?.height,
        gravity: options?.gravity,
        crop: options?.crop,
        format: options?.format || 'png',
        quality: options?.quality || 70,
      },
    });
    return response.data;
  } else {
    // otherwise, just return the original path
    return { url: path, path: path };
  }
};
