import Link from 'next/link';
import { FC } from 'react';

import { Module, Country, VenueDetails } from '../../services/types';
import { LocationLink } from '../locationLink';

type Params = {
  className?: string;
  country: Country;
  location?: VenueDetails;
  module?: Module;
  tabIndex: number;
};
export const OffsiteNavigation: FC<Params> = ({ country, location, tabIndex }) => {
  return (
    <ul className="offsite text-xs">
      <li>
        <LocationLink slug="locations" country={country}>
          <a tabIndex={tabIndex}>
            <span className="fas fa-location-dot" />
            Topgolf Locations
          </a>
        </LocationLink>
      </li>
      {country == 'us' && (
        <li>
          <Link
            href="https://shop.topgolf.com/?utm_source=tg_site&utm_medium=tg_site_links&utm_campaign=none&utm_ad group=none&utm_keyword=none&utm_tactic=sitelink&utm_metric=none&utm_placement=bottom-nav-shop-golf-clubs&utm_category=clubs&utm_landing=tg_shop_home&utm_content=sitelink&utm_promotion=none&utm_audience=none&utm_cdp=none&utm_team=cgi"
            target="_blank"
            className="">
            <span className="fas fa-cart-shopping" />
            Shop Clubs &amp; Gear
          </Link>
        </li>
      )}
      <li>
        {country == 'uk' && location?.alias !== 'glasgow' ? (
          <Link href="http://play.topgolf.com/" target="_blank" tabIndex={tabIndex}>
            <span className="fas fa-user" />
            Member Login
          </Link>
        ) : (
          <Link href="https://myaccount.topgolf.com/" target="_blank" tabIndex={tabIndex}>
            <span className="fas fa-user" />
            Your Topgolf Account
          </Link>
        )}
      </li>

      {/* {country == 'uk' ? (
          <>
            <Link href="http://play.topgolf.com/">
              <a
                className="text-blue-hover hover:text-brand-light dark:text-brand-light dark:hover:text-white-blue"
                target="_blank"
                tabIndex={tabIndex}>
                <span className="fas fa-user-circle" /> Member Login
              </a>
            </Link>
          </>
        ) : (
          <>
            <Link href="https://myaccount.topgolf.com/">
              <a
                className="text-blue-hover hover:text-brand-light dark:text-brand-light dark:hover:text-white-blue"
                target="_blank"
                tabIndex={tabIndex}>
                <span className="fas fa-user-circle" /> Your Topgolf Account
              </a>
            </Link>
          </>
        )} */}

      <li>
        {country == 'uk' ? (
          <>
            <LocationLink slug="promo/merch" country={country}>
              <a tabIndex={tabIndex}>
                <span className="fas fa-shopping-cart" /> Topgolf Merch
              </a>
            </LocationLink>
          </>
        ) : (
          <>
            <LocationLink slug="faq/" country={country} location={location?.alias}>
              <a tabIndex={tabIndex}>
                <span className="fas fa-circle-question" />
                Frequently Asked Questions
              </a>
            </LocationLink>
          </>
        )}
      </li>
    </ul>
  );
};
