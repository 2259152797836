import Link, { LinkProps } from 'next/link';
import React, { FC } from 'react';

import { Country } from '../services/types';
import { getLink } from '../utils/location';

type Props = Omit<LinkProps, 'href'> & {
  slug: string;
  country: Country;
  location?: string;
  hash?: string;
  variables?: string;
  children?: React.ReactNode;
  className?: string;
};

export const LocationLink: FC<Props> = ({
  children,
  slug,
  country,
  location,
  hash,
  variables,
  className,
  ...props
}) => {
  let link = getLink(slug, country, location);
  if (hash) {
    link += `#${hash}`;
  }
  if (variables) {
    link += `?${variables}`;
  }
  return (
    <Link href={link} {...props} className={className} legacyBehavior>
      {children}
    </Link>
  );
};
