import classNames from 'classnames';
import { FC } from 'react';

import { useNearestLocations } from '../../hooks/useNearestLocations';
import { useLocationContext } from '../../providers/locationProvider';
import { useUserContext } from '../../providers/userProvider';
import { NearestLocation } from '../elements/nearestLocation';
import { Spinner } from '../elements/spinner';
import { LocationLink } from '../locationLink';

type Props = {
  onToggle: () => void;
};

export const NearestLocations: FC<Props> = ({ onToggle }) => {
  const { location, country } = useLocationContext();
  const { data, isLoading, isError, error } = useNearestLocations({ limit: 3 });
  const { lastSelectedLocation, setLastSelectedLocation } = useUserContext();
  if (!isLoading && !isError && data !== undefined && !lastSelectedLocation) {
    setLastSelectedLocation(data[0]?.data[0]?.id);
  }

  return (
    <div className={classNames('search')}>
      <button
        className="flex items-center lg:items-end justify-between w-full px-3.5 lg:px-0 h-7 sm:h-8 md:h-9 lg:h-auto"
        type="button"
        onClick={onToggle}>
        <div className="flex items-center">
          <i className="fas fa-location-arrow text-accent-cyan lg:text-brand-bright lg:dark:text-accent-cyan text-xs lg:text-sm mr-2" />
          <span className="text-white lg:text-brand lg:dark:text-white uppercase text-xxs sm:text-xs font-shield leading-none">
            Nearby Locations
          </span>
        </div>
        <div className="ml-4 flex items-center">
          <i className="far fa-times-circle text-xs text-accent-neon lg:text-brand-bright lg:dark:text-accent-neon" />
        </div>
      </button>
      <div className="results text-white lg:text-brand lg:dark:text-white px-4 pt-4 lg:px-0 font-shield text-base uppercase">
        {(!data || isLoading) && <Spinner />}

        {isError && (
          <span>
            <>Error: {error}</>
          </span>
        )}

        {!isLoading &&
          !isError &&
          data?.map((group) =>
            group.data.map((item) => (
              <NearestLocation
                key={item.id}
                id={item.id}
                location={item.alias}
                country={item.country}
                name={item.name}
                address={item.address}
                city={item.city}
                state={item.state}
                post_code={item.post_code}
                distance={item.distance}
                active={typeof location?.alias === 'string' && location?.alias === item.alias}
                onLocationClick={(): void => {
                  onToggle();
                }}
              />
            )),
          )}
      </div>
      <div className="p-4 lg:mb-1 lg:-mx-4 lg:pb-0 border-t-2 border-brand lg:border-white lg:dark:border-brand-navy">
        <div className="flex items-center">
          <div className="w-5">
            <i className="fas fa-map-marker-alt text-accent-cyan lg:text-brand-bright lg:dark:text-accent-cyan" />
          </div>
          <LocationLink country={country} slug="locations">
            <a className="block text-xs uppercase font-shield-bold text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan">
              More Locations
            </a>
          </LocationLink>
        </div>
      </div>
    </div>
  );
};
