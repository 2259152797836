import classNames from 'classnames';
import { FC } from 'react';

type Props = {
  title: string;
  isOpen: boolean;
  onOpen: () => void;
  tabIndex: number;
  children?: React.ReactNode;
};

export const SubMenu: FC<Props> = ({ title, isOpen, onOpen, children, tabIndex }) => {
  return (
    <li className={classNames('parent', { open: isOpen })}>
      <button
        type="button"
        onClick={onOpen}
        aria-expanded={isOpen}
        tabIndex={tabIndex}
        className={''}>
        {title}
      </button>
      {children}
    </li>
  );
};
