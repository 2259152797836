import { FC } from 'react';

import { useToggle } from '../../hooks/useToggle';
import { VenueDetails } from '../../services/types';

import { ChooseLocation } from './chooseLocation';
import { CurrentLocation } from './currentLocation';

type Props = {
  location?: VenueDetails;
};

export const LocationNavigation: FC<Props> = ({ location }) => {
  const [chooseLocation, toggleChooseLocation] = useToggle(false);

  return (
    <div className="relative px-4 lg:p-0 bg-brand lg:bg-white lg:dark:bg-brand-navy">
      <div className="-mb-[6px] lg:mb-0 relative nav-location bg-brand-bright lg:bg-white-blue lg:dark:bg-brand-navy-light lg:px-4 lg:py-3 lg:text-sm rounded-[4px] z-10 shadow-[0_4px_8px_-2px_rgba(0,0,0,0.3)] lg:shadow-none">
        {location && (
          <CurrentLocation
            hidden={chooseLocation}
            toggleChooseLocation={toggleChooseLocation}
            location={location}
          />
        )}
        {(!location || chooseLocation) && (
          <ChooseLocation list={chooseLocation} toggleList={toggleChooseLocation} />
        )}
      </div>
    </div>
  );
};
