import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { Country } from '../services/types';
import { getLink } from '../utils/location';

export const usePathActive = (): {
  isPathActive: (slug: string, country: Country, location: string | undefined) => boolean;
} => {
  const { asPath } = useRouter();

  const isPathActive = useCallback(
    (slug: string, country: Country, location: string | undefined): boolean => {
      return (
        asPath.endsWith(getLink(slug, country, location)) ||
        asPath.endsWith(getLink(slug, country, undefined))
      );
    },
    [asPath],
  );

  return {
    isPathActive,
  };
};
