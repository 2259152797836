import classNames from 'classnames';
import Link from 'next/link';
import { FC } from 'react';

import { useToggle } from '../../hooks/useToggle';
import { VenueDetails } from '../../services/types';
import { TodayHours } from '../../utils/time';
import { PreferLocation } from '../elements/preferLocation';

type Props = {
  location: VenueDetails;
  toggleChooseLocation: () => void;
  hidden: boolean;
};

export const CurrentLocation: FC<Props> = ({ location, toggleChooseLocation, hidden }) => {
  const [infoVisible, toggleInfo] = useToggle(false);

  return (
    <div className={classNames('block info', { hidden })}>
      <button
        className="flex items-center lg:items-end justify-between w-full px-2.5 lg:px-0 h-7 sm:h-8 md:h-9 lg:h-auto gap-2"
        type="button"
        onClick={toggleInfo}>
        <span className="sr-only">Toggle More Venue Information</span>
        <div className="uppercase flex-1 flex lg:flex-col items-center lg:items-start justify-between lg:justify-start lg:ml-0 gap-2 lg:gap-1">
          <div className="flex-1 flex items-center justify-start flex-wrap gap-2">
            <div className="text-[11px] sm:text-xs lg:text-sm font-shield-black text-white lg:text-brand-bright lg:dark:text-white leading-none text-left">
              {location.name_short}
            </div>
            {location.status && (
              <div
                className={classNames(
                  'lg:hidden rounded-sm px-1 pt-[3px] pb-0.5 leading-none font-shield-semibold-condensed uppercase tracking-wide text-[8px]',
                  location.status_bg,
                )}>
                {location.status_msg}
              </div>
            )}
          </div>
          {location?.upcoming ? (
            <div className="text-xxs sm:text-xs font-shield text-white lg:text-body lg:dark:text-white leading-none">
              Coming Soon
            </div>
          ) : (
            <>
              {location?.hours && (
                <div className="text-xxs sm:text-xs font-shield text-left text-white lg:text-body lg:dark:text-white leading-none">
                  <TodayHours hours={location?.hours} specialhours={location?.specialhours} />
                </div>
              )}
            </>
          )}
        </div>
        <div className="text-xxs lg:text-sm leading-none">
          {!infoVisible && (
            <i className="fas fa-chevron-down text-accent-neon lg:text-green-light lg:dark:text-accent-neon" />
          )}
          {infoVisible && (
            <i className="fas fa-chevron-up text-accent-neon lg:text-green-light lg:dark:text-accent-neon" />
          )}
        </div>
      </button>
      {location.status && (
        <div
          className={classNames(
            'hidden lg:block rounded-sm px-1.5 py-1 mt-3 -mx-2 leading-tight text-center font-shield-bold uppercase tracking-wide text-xxs',
            location.status_bg,
          )}>
          {location.status_msg}
        </div>
      )}
      {infoVisible && (
        <div className="animated animate-fade-in border-t border-brand lg:border-none px-2.5 pb-4 lg:p-0">
          <div className="mx-auto sm:max-w-screen-sm md:max-w-screen-md">
            <div className="flex justify-between items-center lg:flex-col lg:items-start mt-4 mb-6 text-white lg:text-body lg:dark:text-white">
              <div className="flex-1 lg:mb-4">
                <div className="text-sm lg:text-xs leading-tight">
                  <span className="block">{location.address}</span>
                  <span>
                    {location.city}, {location.state}
                  </span>
                  <span> {location.post_code}</span>
                </div>
                {location?.upcoming ? (
                  ''
                ) : (
                  <Link
                    href={`tel:${location.phone}`}
                    className="inline-block text-sm lg:text-xs font-shield-bold mt-2 text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan">
                    {location.phone}
                  </Link>
                )}
              </div>
              <div className="flex-1 flex flex-col sm:flex-row lg:flex-col flex-wrap justify-between sm:justify-end lg:justify-between items-center lg:items-start gap-4 sm:gap-8 lg:gap-4">
                {location.address == 'TBA' || location.alias == 'canton' ? (
                  ''
                ) : (
                  <>
                    {['chigwell', 'surrey', 'watford'].includes(location.alias) && (
                      <>
                        <div className="flex items-center gap-2 text-sm">
                          <div className="w-4 flex items-center justify-center">
                            <i className="fas fa-sterling-sign text-sm hover:text-white lg:text-brand-bright lg:hover:text-brand-bright lg:dark:text-accent-cyan lg:dark:hover:text-accent-cyan" />
                          </div>
                          <Link
                            href={`/${location.country}/${location.alias}/pricing`}
                            className="block text-xxs lg:text-xs uppercase font-shield text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan">
                            View Pricing
                          </Link>
                        </div>
                      </>
                    )}
                    {['glasgow'].includes(location.alias) && (
                      <>
                        <div className="flex items-center gap-2 text-sm">
                          <div className="w-4 flex items-center justify-center">
                            <i className="fas fa-sterling-sign text-sm hover:text-white lg:text-brand-bright lg:hover:text-brand-bright lg:dark:text-accent-cyan lg:dark:hover:text-accent-cyan" />
                          </div>
                          <Link
                            href={`#pricing-glasgow`}
                            className="block text-xxs lg:text-xs uppercase font-shield text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan">
                            View Pricing
                          </Link>
                        </div>
                      </>
                    )}
                    <div className="flex items-center gap-2 text-sm">
                      <div className="w-4 flex items-center justify-center">
                        <i className="fas fa-directions text-accent-yellow lg:text-brand-bright lg:dark:text-accent-cyan" />
                      </div>
                      <a
                        href={
                          location.upcoming
                            ? `https://www.google.com/maps/dir/Current+Location/${location.latitude},${location.longitude}`
                            : `https://www.google.com/maps/dir/Current+Location/Topgolf,%20${location.address},%20${location.city},%20${location.state},%20${location.post_code}`
                        }
                        className="block text-xxs lg:text-xs uppercase font-shield text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan"
                        target="_blank"
                        rel="noreferrer">
                        Get Directions
                      </a>
                    </div>
                  </>
                )}
                <div className="flex items-center text-xxs lg:text-xs">
                  <PreferLocation
                    location={location.id}
                    buttonSize="h-4 w-4 text-xxs"
                    caption="Set as Favorite"
                  />
                </div>
              </div>
            </div>
            <div className="lg:-mx-4 lg:border-t-4 border-white dark:border-brand-navy flex justify-between lg:flex-col lg:justify-start gap-4 sm:gap-6 lg:gap-0">
              <Link
                href={`/${location.country}/${location.alias}`}
                className="px-3 py-1.5 lg:p-4 bg-white hover:bg-brand lg:bg-transparent lg:hover:bg-transparent border border-white hover:border-brand lg:border-0 text-brand dark:text-brand hover:text-white dark:hover:text-white lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan rounded-full lg:rounded-none flex-1 text-xxs sm:text-xs flex items-center justify-center lg:justify-start font-shield-bold uppercase">
                <div className="lg:w-4 mr-2 text-left flex items-center justify-center">
                  <i className="fas fa-info-circle text-sm hover:text-white lg:text-brand-bright lg:hover:text-brand-bright lg:dark:text-accent-cyan lg:dark:hover:text-accent-cyan" />
                </div>
                Location Info
              </Link>
              <button
                className="px-3 py-1.5 lg:p-4 lg:pb-1 bg-transparent hover:bg-brand lg:hover:bg-transparent border border-white hover:border-brand lg:border-x-0 lg:border-b-0 lg:border-t-4 lg:hover:border-white lg:dark:border-brand-navy lg:dark:hover:border-brand-navy text-white lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan rounded-full lg:rounded-none flex-1 text-xxs sm:text-xs flex items-center justify-center lg:justify-start font-shield-bold uppercase"
                onClick={toggleChooseLocation}>
                <div className="lg:w-4 mr-2 text-left flex items-center justify-center">
                  <i className="fas fa-location-arrow text-sm hover:text-white lg:text-brand-bright lg:hover:text-brand-bright lg:dark:text-accent-cyan lg:dark:hover:text-accent-cyan" />
                </div>
                Change Location
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
