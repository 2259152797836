import classNames from 'classnames';
import { FC } from 'react';

import { useUserContext } from '../../providers/userProvider';

type Props = {
  location: number;
  className?: string;
  buttonSize?: string;
  buttonDark?: boolean;
  buttonBlue?: boolean;
  caption?: string;
  captionClass?: string;
};

export const PreferLocation: FC<Props> = ({
  location,
  className,
  buttonSize = 'h-5 w-5 text-xs',
  buttonDark = false,
  buttonBlue = false,
  caption = '',
  captionClass = 'text-xxs lg:text-xs uppercase font-shield text-white hover:text-accent-cyan lg:text-brand lg:hover:text-brand-bright lg:dark:text-white lg:dark:hover:text-accent-cyan border-b-2 border-accent-mint lg:dark:border-accent-neon hover:border-accent-yellow lg:dark:hover:border-accent-yellow',
}) => {
  const { preferredLocation, setPreferredLocation } = useUserContext();
  const currentLocationPreferred = preferredLocation === location;

  const handleFavourite = (): void => {
    setPreferredLocation(currentLocationPreferred ? 0 : location);
  };

  return (
    <span className={classNames('inline-block', className)}>
      <button onClick={handleFavourite} className="flex flex-wrap items-center gap-2">
        <div
          className={classNames(
            'fav rounded-full flex items-center justify-center hover:!text-white shadow leading-none',
            buttonSize,
            {
              '!text-gray-warm dark:!text-white border border-solid !bg-brand-navy hover:!bg-accent-red':
                !currentLocationPreferred && buttonDark,
              '!text-white-blue border-2 border-solid border-brand-bright !bg-brand hover:!bg-accent-red':
                !currentLocationPreferred && buttonBlue,
              '!text-body dark:!text-white border border-solid !bg-white dark:!bg-opacity-10 hover:!bg-accent-red dark:hover:!bg-opacity-100 hover:!text-white':
                !currentLocationPreferred && !buttonDark && !buttonBlue,
              '!text-white !bg-accent-red': currentLocationPreferred,
            },
          )}
          aria-current={currentLocationPreferred ? true : false}>
          <span className="sr-only">Favorite</span>
          <i
            className={classNames('fa-heart', {
              far: !currentLocationPreferred,
              fas: currentLocationPreferred,
            })}
            style={{ marginTop: '1px' }}
          />
        </div>
        {caption && <div className={classNames(captionClass)}>{caption}</div>}
      </button>
    </span>
  );
};
