import classNames from 'classnames';
import React, { FC } from 'react';

import { useImgProxy } from '../../hooks/useImgProxy';
import { useUserContext } from '../../providers/userProvider';
import { s3 } from '../../utils/env';
import { trimLeft } from '../../utils/string';

// 2x1 png image for lazy loading with fill #00217d
const preload =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAD0lEQVR42mNkUKz9zwAEAAjiAZ+bFKsOAAAAAElFTkSuQmCC';

type Props = {
  src: string;
  alt: string;
  dark?: string;
  mobile?: string;
  wrapperClass?: string;
  className?: string;
  resizeMethod?: string;
  s3Prefix?: boolean;
  width: number;
  height: number;
  loading?: 'lazy' | 'eager';
  priority?: boolean;
  children?: React.ReactNode;
  format?: string;
};

export const Img: FC<Props> = ({
  children,
  src,
  alt,
  dark,
  mobile,
  width,
  height,
  wrapperClass = 'overflow-hidden',
  className = 'min-w-full',
  resizeMethod = 'cover',
  s3Prefix = true,
  loading = 'lazy',
  priority,
  format = 'png',
}) => {
  const { theme } = useUserContext();
  let url = src;
  let ratioW = width;
  let ratioH = height;
  let preloadImg = preload;
  if (s3Prefix && !src.includes('://')) {
    url = s3 + trimLeft(src, '/');
    if (mobile) {
      mobile = s3 + trimLeft(mobile, '/');
    } else {
      if (width > 960) {
        const ratio = 960 / width;
        ratioW = Math.ceil(width * ratio);
        ratioH = Math.ceil(height * ratio);
      }
    }
  }
  if (theme === 'dark' && dark) {
    if (s3Prefix) {
      dark = s3 + trimLeft(dark, '/');
    }
    url = dark;
  }
  const mimpx = useImgProxy(mobile || url, {
    width: ratioW,
    height: ratioH,
    fit: (!['none', 'fit'].includes(resizeMethod) && resizeMethod) || undefined,
    format,
  });
  const impx = useImgProxy(url, {
    width,
    height,
    fit: (!['none', 'fit'].includes(resizeMethod) && resizeMethod) || undefined,
    format,
  });
  if (!url.includes('s3.topgolf.com')) {
    preloadImg = url;
  }

  return (
    <>
      <picture className={wrapperClass}>
        {mobile && <source srcSet={mimpx.data?.url || preloadImg} media={'(max-width: 960px)'} />}
        {children}
        <img
          src={impx.data?.url || preloadImg}
          className={classNames(className, { 'object-cover': !impx.data?.url })}
          alt={alt}
          width={width}
          height={height}
          loading={loading && !priority ? loading : undefined}
        />
      </picture>
    </>
  );
};

type SourceProps = {
  srcSet: string;
  media?: string;
  s3Prefix?: boolean;
  width?: number;
  height?: number;
  resizeMethod?: string;
};

export const Source: FC<SourceProps> = ({
  srcSet,
  media,
  s3Prefix = true,
  width,
  height,
  resizeMethod = 'cover',
}) => {
  let url = srcSet;
  let preloadImg = preload;
  if (s3Prefix) {
    url = s3 + trimLeft(srcSet, '/');
  }
  const impx = useImgProxy(url, { width, height, fit: resizeMethod });
  if (!url.includes('s3.topgolf.com')) {
    preloadImg = url;
  }
  return (
    <>
      <source
        srcSet={impx.data?.url || preloadImg}
        media={media}
        className={classNames({ 'object-cover': !impx.data?.url })}
      />
    </>
  );
};
