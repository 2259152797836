import classNames from 'classnames';
import React, { FC } from 'react';

type Props = {
  className?: string;
};

export const LogoShield: FC<Props> = ({ className = 'text-brand dark:text-white' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144 136.46">
      <defs />
      <title>Topgolf Logo Shield</title>
      <path
        className={classNames('fill-current', className)}
        d="M396,237.77a224.35,224.35,0,0,0-72,11.76,226.38,226.38,0,0,0,72,124.71,226.37,226.37,0,0,0,72-124.71A224.36,224.36,0,0,0,396,237.77Zm41.21,71.8-.26-.23a31,31,0,0,0-2.46-2.73c-.89-.88-1.88-1.76-2.94-2.64L420.42,302a13.51,13.51,0,0,1,1.06,1.34,8.39,8.39,0,0,1,1,2,5,5,0,0,1,.29,2,4.1,4.1,0,0,1-.3,1.27l11.91,5.88c-.48.82-1,1.64-1.44,2.45l-12.23-6.16a10,10,0,0,1-2.31,1.3,19.46,19.46,0,0,1-3.85,1.08,35.23,35.23,0,0,1-5.2.52,55.77,55.77,0,0,1-6.49-.2l7.83,17.83a70,70,0,0,0,13.41-.8A213.46,213.46,0,0,1,396,363a215.27,215.27,0,0,1-36.81-45.75,100.24,100.24,0,0,0,11.33,5.42,121.83,121.83,0,0,0,15.87,5.16,125.49,125.49,0,0,0,16.75,3l-5.94-18c-2.49-.41-5-.94-7.43-1.58s-4.92-1.4-7.23-2.23-4.44-1.72-6.44-2.66a53.88,53.88,0,0,1-5-2.69l-17.18,4.35q-.93-1.72-1.82-3.44l15.3-3.46a21.06,21.06,0,0,1-3-2.81,10.47,10.47,0,0,1-1.66-2.59,5.36,5.36,0,0,1-.48-2.29,4.07,4.07,0,0,1,.32-1.46h0l-17.88-3.13a212.7,212.7,0,0,1-11-33.61,218.62,218.62,0,0,1,124.47,0A215.22,215.22,0,0,1,437.21,309.56Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M404.21,299.19a1.26,1.26,0,0,1-.19.6,2.41,2.41,0,0,1-1.17.87,7.83,7.83,0,0,1-2.21.48,18.19,18.19,0,0,1-3.17,0l3,6.81a35.67,35.67,0,0,0,8.59-.07,11.28,11.28,0,0,0,4.94-1.72,3.23,3.23,0,0,0,1.5-2.67,4.59,4.59,0,0,0-.88-2.46Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M399.36,298a.82.82,0,0,0-.05-.83,3.19,3.19,0,0,0-.91-.93,9.08,9.08,0,0,0-1.5-.87c-.28-.13-.59-.26-.9-.39v-1.43l.7.22c.91.31,1.77.65,2.57,1a19.43,19.43,0,0,1,2.21,1.17,11.09,11.09,0,0,1,1.24.9l9.6,1.72a25.61,25.61,0,0,0-3.57-2.49,44.06,44.06,0,0,0-4.88-2.44c-1.7-.73-3.53-1.41-5.42-2-.8-.25-1.62-.49-2.45-.72v-1.29c.9.24,1.8.49,2.67.75q1.62.49,3.2,1t3.11,1.16l4.12-.93q-2.17-.83-4.41-1.6t-4.47-1.43c-1.39-.41-2.8-.81-4.22-1.18V268.53c6.92-3.71,14.63,1.91,19.6-8.18-6.67,2.06-10.74-3.44-19.6-3.52h-4.11v37.09c-.38,0-.74-.08-1.08-.09a7.64,7.64,0,0,0-1.64.1,2.5,2.5,0,0,0-1.08.42.75.75,0,0,0-.31.75,1.87,1.87,0,0,0,.65,1,7.13,7.13,0,0,0,1.5,1,14.55,14.55,0,0,0,2.15.88,17.68,17.68,0,0,0,2.51.62,13.14,13.14,0,0,0,2.31.2,5.66,5.66,0,0,0,1.64-.22A1.42,1.42,0,0,0,399.36,298Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M420.08,296.54c-1.42-.75-2.88-1.46-4.36-2.14-1-.44-1.93-.85-2.91-1.27l-4.1,1c.55.25,1.11.5,1.64.77,1,.49,1.91,1,2.81,1.51s1.81,1.09,2.64,1.65c.68.46,1.31.94,1.92,1.42l10.37,1.86c-1.19-.83-2.41-1.63-3.67-2.39S421.56,297.31,420.08,296.54Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M373.58,287l-6.84-3.44c-1.61,0-3.2.1-4.75.23s-3.31.34-4.88.61a41.73,41.73,0,0,0-4.79,1.09,28.35,28.35,0,0,0-4,1.54l15.92,2.85a8.68,8.68,0,0,1,1.6-1,14.53,14.53,0,0,1,2.37-.91,23.15,23.15,0,0,1,2.69-.61C371.72,287.23,372.63,287.12,373.58,287Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M379.35,286.93c1.05,0,2.14.11,3.24.21s2.2.23,3.33.38,2.07.31,3.13.5l-.78-2.38c-1.52-.3-3-.57-4.51-.8s-3.21-.46-4.78-.64-3.21-.32-4.79-.43c-.65,0-1.29-.06-1.94-.09l6.5,3.22Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M378.73,288.8a19,19,0,0,0-4.45.83,6.74,6.74,0,0,0-2.76,1.61l11.66,2.09a2.38,2.38,0,0,1,.8-.6,6.46,6.46,0,0,1,1.76-.48,14.34,14.34,0,0,1,2.29-.13,23.21,23.21,0,0,1,2.42.18l-.94-2.85a54.31,54.31,0,0,0-5.52-.66A37.25,37.25,0,0,0,378.73,288.8Z"
        transform="translate(-324 -237.77)"
      />
      <path
        className={classNames('fill-current', className)}
        d="M389.6,299.32a24.4,24.4,0,0,1-3.15-1.41,13,13,0,0,1-2.27-1.52,5.8,5.8,0,0,1-.82-.86l-12.74-2.23a4.88,4.88,0,0,0,1.17,2.94,18.11,18.11,0,0,0,4.83,4.08,46.25,46.25,0,0,0,8.35,4,64.68,64.68,0,0,0,10.4,2.87l-2.24-6.81A31.78,31.78,0,0,1,389.6,299.32Z"
        transform="translate(-324 -237.77)"
      />
    </svg>
  );
};
