import React, { createContext, FC, useContext, useEffect } from 'react';

import { Country, VenueDetails } from '../services/types';

import { useUserContext } from './userProvider';

const LocationContext = createContext<UseLocation | null>(null);
LocationContext.displayName = 'LocationContext';

type UseLocation = {
  country: Country;
  location?: VenueDetails;
};

export const useLocationContext = (): UseLocation => useContext(LocationContext) as UseLocation;

type Props = {
  country: Country;
  location?: VenueDetails;
  children?: React.ReactNode;
};

export const LocationProvider: FC<Props> = ({ children, country, location }) => {
  const { lastSelectedLocation, setLastSelectedLocation } = useUserContext();
  useEffect(() => {
    if (location && (!lastSelectedLocation || location.id !== lastSelectedLocation)) {
      setLastSelectedLocation(location.id);
    }
  }, [lastSelectedLocation, setLastSelectedLocation, location]);
  return (
    <LocationContext.Provider value={{ country, location }}>{children}</LocationContext.Provider>
  );
};
